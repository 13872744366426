<template>
  <div id="id-back-capture-cam">
    <main id="ocr">
      <div class="w-full text-4xl absolute text-black font-bold text-center z-50 top-[3%]">Back ID</div>
      <div v-if="!loading" class="frame"></div>
      <canvas id="camera--sensor" ref="cameraSensorId"></canvas>
      <video id="camera--view" autoplay playsinline ref="cameraViewId"></video>
      <img :src="imageSrc" alt="" id="camera--output" ref="cameraOutputId" :class="{ hidden: !imageCaptured }">
      <button class="z-50" type="button" @click="takeIdPicture" id="camera--trigger" :disabled="loading">
        <span v-if="!loading">Take a picture</span>
        <span v-else>Loading...</span>
      </button>
      <div v-if="loading" class="full-screen-overlay">
        <div class="loader z-50"></div>
      </div>
    </main>
  </div>
</template>

<script>
import store from '../store';
require('@/assets/ekyc-camera.css');

export default {
  name: "IDBackCaptureCamera",
  props: {
    errorCount:{
      type: Number,
      required: true
    },
  },
  data() {
    return {
      loading: false,
      constraints: {
        video: {
          facingMode: "environment",
          width: { min: 520, ideal: 1280, max: 1280 },
          height: { min: 520, ideal: 720, max: 1280 }
        },
        audio: false
      },
      trackId: null,
      base64IdData: "",
      imageSrc: '',
      imageCaptured: false,
      mediaStream: null,
    };
  },
  mounted() {
    console.log('[EKYC Back ID] Error count:', this.errorCount);
    const journeyId = sessionStorage.getItem('journeyId');
    console.log('[EKYC Back ID] Mounted with journey ID:', journeyId);
    if (!journeyId) {
      console.warn('[EKYC Back ID] No journey ID found, redirecting to start');
      this.$router.push({ name: 'start-ekyc' });
    }
    this.startCamera();
  },
  beforeDestroy() {
    this.closeCamera();
  },
  methods: {
    startCamera() {
      console.log('[EKYC Back ID] Starting camera');
      navigator.mediaDevices
          .getUserMedia(this.constraints)
          .then(stream => {
            console.log('[EKYC Back ID] Camera started successfully');
            this.mediaStream = stream;
            this.trackId = stream.getTracks()[0];
            this.$refs.cameraViewId.srcObject = stream;
          })
          .catch(error => {
            console.error("[EKYC Back ID] Camera error:", error);
          });
    },
    closeCamera() {
      let tracks = this.mediaStream.getTracks();
      tracks.forEach(track => track.stop() )
    },
    async takeIdPicture() {
      console.log('[EKYC Back ID] Taking picture');
      
      // Mixpanel tracking
      if (process.env.VUE_APP_ENV === 'production'){
        try {
          this.$mixpanel.track('E-Kyc ID Back Image Captured', {
            userId_Name: `${store.state.auth.user.id} ${store.state.auth.user.name}`,
            current_form: 'E-Kyc ID Back Capture Camera',
            selected_document_type: sessionStorage.getItem('documentType'),
            journey_id: sessionStorage.getItem('journeyId')
          });
        } catch (error) {
          console.log('[EKYC Back ID] Mixpanel error:', error);
        }
      }

      this.loading = true;
      console.log('[EKYC Back ID] Processing image capture');

      const cameraViewId = this.$refs.cameraViewId;
      const cameraSensorId = this.$refs.cameraSensorId;

      cameraSensorId.width = cameraViewId.videoWidth;
      cameraSensorId.height = cameraViewId.videoHeight;
      console.log('[EKYC Back ID] Image dimensions:', { width: cameraSensorId.width, height: cameraSensorId.height });

      cameraSensorId.getContext("2d").drawImage(cameraViewId, 0, 0);
      const imageDataUrl = cameraSensorId.toDataURL("image/jpeg", 1.0);
      
      console.log('[EKYC Back ID] Image captured successfully');
      
      this.imageSrc = imageDataUrl;
      this.imageCaptured = true;
      this.base64IdData = imageDataUrl.replace(/^data:image\/[^;]+;base64,/, '');

      const journeyId = sessionStorage.getItem('journeyId');
      const docFront = sessionStorage.getItem('docFront');
      
      console.log('[EKYC Back ID] Retrieved from session storage:', {
        hasJourneyId: !!journeyId,
        hasDocFront: !!docFront
      });

      this.closeCamera();

      try {
        console.log('[EKYC Back ID] Calling centralize-okay-id API');
        let response = await this.$http.post('/api/centralize-okay-id', {
          journeyId,
          docFront,
          docBack: this.base64IdData
        });
        
        console.log('[EKYC Back ID] API Response:', response.data);

        if (response.data && response.data.status === 'success') {
          console.log('[EKYC Back ID] API call successful');
          this.$toast.success('Back ID Captured Successfully');
          this.$emit('next-step', true);

          try {
            await this.$http.post('/api/update-okay-id-status', {
              journeyId,
              status: 'success'
            });
            console.log('Status updated to success');
          } catch (statusError) {
            console.log('Error updating status:', statusError);
          }

        } else {
          console.warn('[EKYC Back ID] API returned non-success status');
          // Mixpanel tracking
          if (process.env.VUE_APP_ENV === 'production'){
            try {
              this.$mixpanel.track('E-Kyc ID Back Image captured unsuccessfully due to inappropriate image format', {
                userId_Name: `${store.state.auth.user.id} ${store.state.auth.user.name}`,
                current_form: 'E-Kyc ID Back Capture Camera',
                selected_document_type: sessionStorage.getItem('documentType'),
                journey_id: sessionStorage.getItem('journeyId'),
                api_response: response.data,
              });
            } catch (error) {
              console.log('[EKYC Back ID] Mixpanel error:', error);
            }
          }

          try {
            await this.$http.post('/api/update-okay-id-status', {
              journeyId,
              status: 'failed'
            });
            console.log('Status updated to failed');
          } catch (statusError) {
            console.log('Error updating status:', statusError);
          }

          this.$toast.error('Inappropriate image format, please retake');
          this.handleError();
        }
      } catch (error) {
        console.error('[EKYC Back ID] Error during API call:', error);
        // Mixpanel tracking
        if (process.env.VUE_APP_ENV === 'production'){
          try {
            this.$mixpanel.track('E-Kyc ID Back Image captured unsuccessfully due to error centralizing Okay ID', {
              userId_Name: `${store.state.auth.user.id} ${store.state.auth.user.name}`,
              current_form: 'E-Kyc ID Back Capture Camera',
              selected_document_type: sessionStorage.getItem('documentType'),
              journey_id: sessionStorage.getItem('journeyId'),
              error: error.response ? error.response.data : error,
            });
          } catch (error) {
            console.log('[EKYC Back ID] Mixpanel error:', error);
          }
        }

        console.error('[EKYC Back ID] Error centralizing Okay ID:', error);
        this.$toast.error('Please attempt again, ensure the document/selfie is clear and not obstructed'); 
        this.handleError();
      } finally {
        this.loading = false;
      }
    },
    handleError() {
      console.log('[EKYC Back ID] Handling error, current count:', this.errorCount);
      const key = this.$route.query.key;

      if (this.errorCount >= 1) {
        console.log('[EKYC Back ID] Max attempts reached, redirecting to summary');
        this.closeCamera();
        this.$toast.error('Exceeded maximum attempt.');
        const journeyId = sessionStorage.getItem('journeyId');
        if (journeyId){
          console.log('[EKYC Back ID] Redirecting to summary with journeyId:', journeyId);
          this.$router.push({ name: 'document-summary', query: { JID : journeyId, key } });
        } else {
          console.error('[EKYC Back ID] Journey ID missing during error handling');
        }
      } else {
        console.log('[EKYC Back ID] Retrying capture');
        this.$emit('failed', { type:"doc" });
      }
    }
  }
};
</script>
